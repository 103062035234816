<template>
  <div class="information-page">
    <p class="title">公司信息完善</p>
    <div class="wrap">
      <template v-if="user && user.roleid == '40'">
        <breeding-information></breeding-information>
      </template>
      <template v-if="user && user.roleid == '70'">
        <breeding-information></breeding-information>
      </template>
      <template v-if="user && user.roleid == '30'">
        <breeding-information></breeding-information>
      </template>
      <template v-if="user && user.roleid == '60'">
        <breeding-information></breeding-information>
      </template>
      <template v-if="user && user.roleid == '50'">
        <breeding-information></breeding-information>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import breedingInformation from "./breedingInformation" //养殖户信息完善
export default {
  name: "",
  components: {
    breedingInformation
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  }
};
</script>

<style lang="less" scoped>
.information-page {
  width: 100%;
  height: 100%;
  .title {
    padding: 16px;
    text-align: center;
    font-size: 26px;
    letter-spacing: 3px;
  }
  .wrap {
    width: 1024px;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }
}
</style>