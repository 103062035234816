<template>
  <div class="breed-information">
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <Row>
        <Col span="11">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="养殖户名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <p class="btn-group">
      <span>
        <Button type="primary" :loading="loading" @click="submit">提交</Button>
      </span>
      <span>
        <Button @click="reset">重置</Button>
      </span>
      <span>
        <Button type="primary" @click="logout">退出登录</Button>
      </span>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      form: {},
      rules: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList,
    }),
  },
  methods: {
    submit() {
      this.$store.dispatch("getPermission").then(() => {
        this.$nextTick(() => {
          let routeName = this.getNextRoute();
          this.$router.push({ name: routeName });
        });
      });
    },
    getNextRoute(menus) {
      if (!menus) menus = this.menuList?.children ?? [];
      for (let i = 0; i < menus.length; i++) {
        let menu = menus[i];
        if (menu.url) return menu.url;
        if (menu.children) {
          let subMenu = this.getNextRoute(menu.children);
          if (subMenu) return subMenu;
        }
      }
    },
    reset() {},
    logout() {
      this.$store.dispatch("clearApp").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breed-information {
  width: 100%;
  height: 100%;
  .btn-group {
    text-align: center;
    span {
      padding: 0 10px;
    }
  }
}
</style>